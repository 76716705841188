import { __awaiter, __generator } from "tslib";
import { defineComponent, onMounted, ref } from "vue";
import BaseProductCard from "@/components/BaseProductCard.vue";
import { getPromotionProduct, showProduct } from "@/api/ProductApi";
import { Modal } from "bootstrap";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
export default defineComponent({
    name: "home",
    components: {
        BaseProductCard: BaseProductCard,
    },
    setup: function () {
        var _this = this;
        var products = ref([]);
        var loading = ref(false);
        var product = ref();
        var fullProductRoute = ref("");
        var pagination = ref();
        var store = useStore();
        var getListOfProducts = function (payload) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value = true;
                        // topLoadingToggle(true);
                        return [4 /*yield*/, getPromotionProduct(payload)
                                .then(function (res) {
                                pagination.value = {
                                    page: res.data.meta.current_page,
                                    total: res.data.meta.total,
                                    rowsPerPage: res.data.meta.per_page,
                                    totalPage: res.data.meta.last_page,
                                };
                                products.value = res.data.data;
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        // topLoadingToggle(true);
                        _a.sent();
                        loading.value = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var currentPageChange = function (val) {
            getListOfProducts({
                page: val,
            });
        };
        onMounted(function () {
            // if (!localStorage.getItem("config")) {
            //   LayoutService.enableSidebar();
            // }
            setCurrentPageBreadcrumbs("All Promotion", ["Promotion"]);
            getListOfProducts({});
        });
        // onUnmounted(() => {
        //   if (!localStorage.getItem("config")) {
        //     LayoutService.disableSidebar();
        //   }
        // });
        var quickView = function (id) { return __awaiter(_this, void 0, void 0, function () {
            var productModal, productRes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fullProductRoute.value = window.location.host + "/product/" + id;
                        productModal = new Modal(document.getElementById("kt_modal_product_detail"), {
                            keyboard: false,
                        });
                        return [4 /*yield*/, showProduct(id)];
                    case 1:
                        productRes = _a.sent();
                        product.value = productRes.data.data;
                        productModal.show();
                        return [2 /*return*/];
                }
            });
        }); };
        return {
            products: products,
            loading: loading,
            quickView: quickView,
            product: product,
            fullProductRoute: fullProductRoute,
            pagination: pagination,
            currentPageChange: currentPageChange,
            store: store,
        };
    },
});
